<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动id"
                    class="width300"
                    prop="act_id">
        <el-input type="text"
                  placeholder="请输入邮政活动Id"
                  v-model="form.act_id"></el-input>
      </el-form-item>
      <el-form-item label="开启问卷填写">
        <el-radio-group v-model="form.is_questionnaire">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否开启预约">
        <el-radio-group v-model="form.is_expenses_appointment">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动类型"
                    prop="lucky_type">
        <el-select v-model="form.lucky_type"
                   placeholder="请选择">
          <el-option label="接口活动"
                     value="POSTAL_API">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="验证未通过处理"
                    required>
        <el-radio-group v-model="form.is_take_part">
          <el-radio :label="0">不可参与</el-radio>
          <el-radio :label="1">可参与</el-radio>
          <el-radio :label="2">不验证</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="中奖是否短信通知支行长"
                    required>
        <el-radio-group v-model="form.is_branch_tel">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否必须关联员工码">
        <el-radio-group v-model="form.is_marketer_code">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否需要填写网点机构号"
                    required
                    key="is_need_org"
                    v-if="form.is_take_part !== 2">
        <el-radio-group v-model="form.is_need_org">
          <el-radio :label="false">否</el-radio>
          <el-radio :label="true">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="抽奖类型">
        <el-radio-group v-model="form.draw_type">
          <el-radio :label="1">九宫格</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="封面图片"
                    prop="banner_img_id">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.banner_img_id" />
      </el-form-item>
      <el-form-item label="首页图片"
                    prop="img_id">
        <ImgUpload :fileList="imgList2"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="二维码背景图"
                    prop="qrcode_bg_img_id">
        <ImgUpload :fileList="imgList3"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.qrcode_bg_img_id" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <Editor v-model="form.rule" />
      </el-form-item>
      <el-form-item label="起止时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="每用户最高可参与数"
                    class="width300"
                    prop="each_num">
        <el-input type="text"
                  placeholder="请输入每用户最高可参与数"
                  v-model.number="form.each_num"></el-input>
      </el-form-item>
      <el-form-item label="初始参与数"
                    class="width300"
                    prop="init_num">
        <el-input type="text"
                  placeholder="请输入初始参与数"
                  v-model.number="form.init_num"></el-input>
      </el-form-item>
      <el-form-item label="优先中奖个数"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入优先中奖个数"
                  v-model.number="form.priority_location_num"></el-input>
      </el-form-item>
      <el-form-item label="判断微商城会员">
        <el-radio-group v-model="form.is_verify_micro_mall">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="员工码是否无限制领取">
        <el-radio-group v-model="form.marketer_no_is_infinite_receive">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="提示语"
                    prop="postal_prompt">
        <el-input type="textarea"
                  placeholder="请输入提示语"
                  v-model="form.postal_prompt"></el-input>
      </el-form-item>
      <el-form-item label="核销提示语"
                    prop="qrcode_desc">
        <el-input type="textarea"
                  placeholder="请输入核销提示语"
                  v-model="form.qrcode_desc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
import { setActivity, getActivity } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Editor from '@/components/Editor'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetLucky',
  data () {
    return {
      imgList: [],
      imgList2: [],
      imgList3: [],
      areaAddress: {},
      dateTime: [],
      form: {
        type: 'QUESTIONNAIRE',
        title: '',
        started_at: '',
        is_take_part: 0,
        lucky_type: 'POSTAL_API',
        ended_at: '',
        rule: '',
        img_id: '',
        banner_img_id: '',
        qrcode_bg_img_id: '',
        act_id: '',
        list_province: '',
        is_branch_tel: false,
        list_city: '',
        list_district: '',
        list_net: '',
        draw_type: 1,
        is_marketer_code: 0,
        day_num: '',
        each_num: '',
        total_num: '',
        init_num: 0,
        priority_location_num: 0,
        is_need_org: false,
        is_verify_micro_mall: false,
        marketer_no_is_infinite_receive: false,
        is_questionnaire: true,
        postal_prompt: '很抱歉，您不是本次活动的邀约客户，敬请期待下次活动！请联系客户经理了解快速达级方式，参与活动。',
        qrcode_desc: '客户经理将于10个工作日内联系您到网点现场领取奖品实物，如有疑问请联系您所在网点的客户经理。'
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        list_province: [
          { required: true, message: '至少选择省份', trigger: 'change' }
        ],
        act_id: [
          { required: true, message: '请输入邮政活动id', trigger: 'blur' }
        ],
        lucky_type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        img_id: [
          { required: true, message: '请上传首页图片', trigger: 'change' }
        ],
        banner_img_id: [
          { required: true, message: '请上传封面图片', trigger: 'change' }
        ],
        qrcode_bg_img_id: [
          { required: true, message: '请上传二维码图片', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        each_num: [
          { required: true, message: '请输入每用户最高可参与数', trigger: 'blur' }
        ],
        init_num: [
          { required: true, message: '请输入初始参与数', trigger: 'blur' }
        ],
        postal_prompt: [
          { required: true, message: '请输入提示语', trigger: 'blur' }
        ],
        qrcode_desc: [
          { required: true, message: '请输入核销提示语', trigger: 'blur' }
        ]

      }
    }
  },
  components: {
    Area,
    ImgUpload,
    Editor
  },
  filters: {
  },
  computed: {

  },
  methods: {
    dateChange (e) {
      if (e) {
        this.form.started_at = e[0]
        this.form.ended_at = e[1]
      } else {
        this.form.started_at = ''
        this.form.ended_at = ''
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(
            JSON.stringify(this.form)
          )
          const res = await setActivity(formData)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('questionLucky')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity

        activity.activity_id = id
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList2, 0, activity.img_data)
        activity.banner_img_id = getImgId(activity.banner_img_data)
        activity.banner_img_data && this.$set(this.imgList, 0, activity.banner_img_data)
        activity.qrcode_bg_img_id = getImgId(activity.qrcode_bg_img_data)
        activity.qrcode_bg_img_data && this.$set(this.imgList3, 0, activity.qrcode_bg_img_data)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        if (!activity.is_branch_tel) {
          activity.is_branch_tel = false
        }
        this.dateTime = [activity.started_at, activity.ended_at]
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
